var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-depth-tab-wrap', [_c('div', {
    staticClass: "mb-20 mb-md-30"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    class: {
      'secondary white--text': !_vm.categories__depth2.some(function (_ref) {
        var _vm$category;
        var _id = _ref._id;
        return _id == _vm._category || _id == ((_vm$category = _vm.category) === null || _vm$category === void 0 ? void 0 : _vm$category._parent);
      })
    },
    attrs: {
      "rounded": "",
      "color": "transparent"
    },
    on: {
      "click": function ($event) {
        return _vm.emit(null);
      }
    }
  }, [_vm._v("All")])], 1), _vm._l(_vm.categories__depth2, function (item) {
    var _vm$category2;
    return [_c('v-col', {
      key: item._id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      class: {
        'secondary white--text': item._id == _vm._category || item._id == ((_vm$category2 = _vm.category) === null || _vm$category2 === void 0 ? void 0 : _vm$category2._parent)
      },
      attrs: {
        "rounded": "",
        "color": "transparent"
      },
      on: {
        "click": function ($event) {
          return _vm.emit(item._id);
        }
      }
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))])], 1)];
  })], 2)], 1), _vm.categories__depth3.length ? _c('v-container', [_c('v-tabs-block', [_c('v-tab', [_c('v-btn', {
    class: {
      'v-btn--active': _vm.categories__depth2.some(function (_ref2) {
        var _id = _ref2._id;
        return _id == _vm._category;
      })
    },
    attrs: {
      "outlined": "",
      "block": "",
      "color": "grey-e6"
    },
    on: {
      "click": function ($event) {
        var _vm$categories__depth;
        return _vm.emit((_vm$categories__depth = _vm.categories__depth3[0]) === null || _vm$categories__depth === void 0 ? void 0 : _vm$categories__depth._parent);
      }
    }
  }, [_vm._v(" All ")])], 1), _vm._l(_vm.categories__depth3, function (item) {
    return [_c('v-tab', {
      key: item._id
    }, [_c('v-btn', {
      class: {
        'v-btn--active': _vm._category == item._id
      },
      attrs: {
        "outlined": "",
        "block": "",
        "color": "grey-e6"
      },
      on: {
        "click": function ($event) {
          return _vm.emit(item._id);
        }
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])], 1)];
  })], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }