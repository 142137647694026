<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="Product" tabActive="Products">
                <template slot="subVisualImmersive">
                    <slide-banner v-if="detect == 'pc'" code="product-top-banners-desktop" />
                    <slide-banner v-if="detect == 'mobile'" code="product-top-banners-mobile" />
                </template>
            </sub-visual>
        </template>

        <v-container>
            <form-table hasHead hasFoot @cancel="$router.go(-1)" @submit="submit">
                <form-table-row label="Name" isRequired> <v-text-field v-model="inquiry.writer.name" v-bind="inputAttrs" /> </form-table-row>

                <form-table-row label="Contact" isRequired> <v-text-field v-model="inquiry.writer.phone" v-bind="inputAttrs" /> </form-table-row>

                <form-table-row label="E-mail" isRequired> <v-email-field v-model="inquiry.writer.email" v-bind="inputAttrs" /> </form-table-row>

                <form-table-row label="User Type" isRequired>
                    <v-radio-group v-model="inquiry.meta.isNewUser" row hide-details>
                        <v-radio v-for="{ label, value } in isNewUserItems" :key="value" v-bind="{ label, value }" />
                    </v-radio-group>
                </form-table-row>

                <form-table-row label="Dentium History" isRequired>
                    <v-radio-group v-model="inquiry.meta.hasUsedDentium" row hide-details>
                        <v-radio v-for="{ label, value } in hasUsedDentiumItems" :key="value" v-bind="{ label, value }" />
                    </v-radio-group>
                </form-table-row>

                <form-table-row label="Products of Interest" isRequired>
                    <v-row>
                        <v-col cols="auto" v-for="category in categories" :key="category?._id"> <v-checkbox v-model="inquiry.meta._categories" :label="category?.name" :value="category?._id" hide-details /> </v-col>
                    </v-row>
                </form-table-row>

                <form-table-row label="Product Name" isRequired> <v-autocomplete v-model="inquiry.meta._products" :items="products" :item-text="({ category, name }) => `${category?.name} - ${name}`" item-value="_id" multiple v-bind="inputAttrs" /> </form-table-row>

                <form-table-row label="Inquiry" isRequired> <v-textarea v-model="inquiry.content" auto-grow v-bind="inputAttrs" /> </form-table-row>

                <form-table-row label="Other Message" isRequired> <v-textarea v-model="inquiry.meta.message" auto-grow v-bind="inputAttrs" /> </form-table-row>
            </form-table>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import FormTable from "@/components/client/dumb/form-table.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SlideBanner from "@/components/client/banner/slide-banner.vue";
import VEmailField from "@/components/client/dumb/v-email-field.vue";
import FormTableRow from "@/components/client/dumb/form-table-row.vue";
const inputAttrs = { outlined: true, "hide-details": true };
const isNewUserItems = [
    { label: "New", value: true },
    { label: "Existing", value: false },
];
``;
const hasUsedDentiumItems = [
    { label: "Never Used", value: false },
    { label: "Used or in use", value: true },
];
const initInquiry = () => ({
    writer: {
        _id: null,
        name: null,
        phone: null,
        email: null,
    },
    content: null,
    meta: {
        _zone: null,
        _products: [],
        _categories: [],

        message: null,
        isNewUser: true,
        hasUsedDentium: false,
    },
});

export default {
    components: {
        FormTable,
        ClientPage,
        SubVisual,
        SlideBanner,
        VEmailField,
        FormTableRow,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        user: {},
        product: {},
        products: [],
        categories: [],

        detect,
        inputAttrs,
        isNewUserItems,
        hasUsedDentiumItems,

        inquiry: initInquiry(),
    }),
    computed: {
        countries() {
            return this?.product?.countries?.map?.(({ name }) => name)?.join?.(", ");
        },
    },
    mounted() {
        this.init().then(this.setInquiry);
    },
    methods: {
        async init() {
            this.inquiry = initInquiry();
            this.user = (await api.v1.me.get())?.user || {};
            this.product = (await api.v1.shop.products.get({ _id: this._product }))?.product || {};
            this.products = (await api.v1.shop.products.gets())?.products || [];
            this.categories = (await api.v1.shop.categories.gets())?.categories || [];
        },
        async setInquiry() {
            const { _id, _zone, name, phone, email } = this.user;
            this.inquiry.writer = { _id, name, phone, email };
            const { _id: _product, _category } = this.product;
            if (_zone) this.inquiry.meta._zone = _zone;
            if (_product) this.inquiry.meta._products.push(_product);
            if (_category) this.inquiry.meta._categories.push(_category);
        },
        isInvalid() {
            try {
                if (!this.inquiry.writer.name) throw new Error("NAME is required");
                if (!this.inquiry.writer.phone) throw new Error("CONTACT is required");
                if (!this.inquiry.writer.email) throw new Error("EMAIL is required");
                if (this.inquiry.meta._categories.length < 1) throw new Error("PRODUCTS OF INTEREST are required");
                if (this.inquiry.meta._products.length < 1) throw new Error("PRODUCT NAME is required");
                if (!this.inquiry.content) throw new Error("INQUIRY is required");
                if (!this.inquiry.meta.message) throw new Error("MESSAGE is required");
            } catch (error) {
                alert(error?.message);
                return true;
            }
        },
        async submit() {
            if (this.isInvalid()) return;

            await api.v1.forms.post({ ...this.inquiry, code: "product-inquiry" });
            alert("The inquiry has been submitted");
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-align-left {
    text-align: left !important;
}
</style>
