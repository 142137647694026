var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "Product",
            "tabActive": "Products"
          }
        }, [_c('template', {
          slot: "subVisualImmersive"
        }, [_vm.detect == 'pc' ? _c('slide-banner', {
          attrs: {
            "code": "product-top-banners-desktop"
          }
        }) : _vm._e(), _vm.detect == 'mobile' ? _c('slide-banner', {
          attrs: {
            "code": "product-top-banners-mobile"
          }
        }) : _vm._e()], 1)], 2)];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('form-table', {
    attrs: {
      "hasHead": "",
      "hasFoot": ""
    },
    on: {
      "cancel": function ($event) {
        return _vm.$router.go(-1);
      },
      "submit": _vm.submit
    }
  }, [_c('form-table-row', {
    attrs: {
      "label": "Name",
      "isRequired": ""
    }
  }, [_c('v-text-field', _vm._b({
    model: {
      value: _vm.inquiry.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.writer, "name", $$v);
      },
      expression: "inquiry.writer.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "Contact",
      "isRequired": ""
    }
  }, [_c('v-text-field', _vm._b({
    model: {
      value: _vm.inquiry.writer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.writer, "phone", $$v);
      },
      expression: "inquiry.writer.phone"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "E-mail",
      "isRequired": ""
    }
  }, [_c('v-email-field', _vm._b({
    model: {
      value: _vm.inquiry.writer.email,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.writer, "email", $$v);
      },
      expression: "inquiry.writer.email"
    }
  }, 'v-email-field', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "User Type",
      "isRequired": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.meta.isNewUser,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.meta, "isNewUser", $$v);
      },
      expression: "inquiry.meta.isNewUser"
    }
  }, _vm._l(_vm.isNewUserItems, function (_ref) {
    var label = _ref.label,
      value = _ref.value;
    return _c('v-radio', _vm._b({
      key: value
    }, 'v-radio', {
      label,
      value
    }, false));
  }), 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "Dentium History",
      "isRequired": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.meta.hasUsedDentium,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.meta, "hasUsedDentium", $$v);
      },
      expression: "inquiry.meta.hasUsedDentium"
    }
  }, _vm._l(_vm.hasUsedDentiumItems, function (_ref2) {
    var label = _ref2.label,
      value = _ref2.value;
    return _c('v-radio', _vm._b({
      key: value
    }, 'v-radio', {
      label,
      value
    }, false));
  }), 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "Products of Interest",
      "isRequired": ""
    }
  }, [_c('v-row', _vm._l(_vm.categories, function (category) {
    return _c('v-col', {
      key: category === null || category === void 0 ? void 0 : category._id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "label": category === null || category === void 0 ? void 0 : category.name,
        "value": category === null || category === void 0 ? void 0 : category._id,
        "hide-details": ""
      },
      model: {
        value: _vm.inquiry.meta._categories,
        callback: function ($$v) {
          _vm.$set(_vm.inquiry.meta, "_categories", $$v);
        },
        expression: "inquiry.meta._categories"
      }
    })], 1);
  }), 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "Product Name",
      "isRequired": ""
    }
  }, [_c('v-autocomplete', _vm._b({
    attrs: {
      "items": _vm.products,
      "item-text": function (_ref3) {
        var category = _ref3.category,
          name = _ref3.name;
        return `${category === null || category === void 0 ? void 0 : category.name} - ${name}`;
      },
      "item-value": "_id",
      "multiple": ""
    },
    model: {
      value: _vm.inquiry.meta._products,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.meta, "_products", $$v);
      },
      expression: "inquiry.meta._products"
    }
  }, 'v-autocomplete', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "Inquiry",
      "isRequired": ""
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "auto-grow": ""
    },
    model: {
      value: _vm.inquiry.content,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry, "content", $$v);
      },
      expression: "inquiry.content"
    }
  }, 'v-textarea', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "Other Message",
      "isRequired": ""
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "auto-grow": ""
    },
    model: {
      value: _vm.inquiry.meta.message,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.meta, "message", $$v);
      },
      expression: "inquiry.meta.message"
    }
  }, 'v-textarea', _vm.inputAttrs, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }