var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "w-md-200px",
    attrs: {
      "small": "",
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.go.apply(null, arguments);
      }
    }
  }, 'v-btn', _vm.$attrs, false), [_vm._t("default"), _c('login-to-continue', {
    ref: "LoginToContinue"
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }