var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('div', {
    staticClass: "slide-banner"
  }, [_c('swiper', {
    ref: "slideBanner",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.banner.slides.filter(function (slide) {
    return slide.image;
  }), function (slide, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('v-card', {
      staticClass: "slide-banner__img",
      style: 'background-image:url(' + slide.image + ');',
      attrs: {
        "tile": "",
        "flat": "",
        "href": slide.url,
        "target": "_blank"
      }
    })], 1);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }), _c('div', {
    staticClass: "ml-28 mr-12"
  }, [_c('div', {
    staticClass: "swiper-play d-none"
  }, [_c('i', {
    staticClass: "icon icon-play-dark"
  })]), _c('div', {
    staticClass: "swiper-pause"
  }, [_c('i', {
    staticClass: "icon icon-pause-dark"
  })])]), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left-sm-dark"
  })]), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right-sm-dark"
  })])])], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }