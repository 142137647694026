var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', _vm._b({
          attrs: {
            "title": "Product"
          }
        }, 'sub-visual', {
          tabActive: _vm.tabActive
        }, false))];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "multiple": ""
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._l(_vm.items, function (item) {
    var _item$image__download, _item$image__download2, _item$catalogue, _item$image__mobile, _item$image__desktop, _item$documents;
    return [_c('v-expansion-panel', {
      key: item._id,
      staticClass: "rounded-0 elevation-0 mb-40 mb-md-60"
    }, [_c('v-divider', {
      staticClass: "border-2 secondary"
    }), _c('v-expansion-panel-header', {
      staticClass: "product-item-header py-20 py-md-30 px-0 tit tit--xs"
    }, [_c('v-row', {
      staticClass: "ml-0"
    }, [_c('v-col', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-row', [item !== null && item !== void 0 && (_item$image__download = item.image__download) !== null && _item$image__download !== void 0 && _item$image__download.url ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "w-md-200px my-n6",
      attrs: {
        "small": "",
        "block": "",
        "outlined": "",
        "color": "grey-d",
        "download": "",
        "href": item === null || item === void 0 ? void 0 : (_item$image__download2 = item.image__download) === null || _item$image__download2 === void 0 ? void 0 : _item$image__download2.url
      },
      on: {
        "!click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_vm._v("Image Download"), _c('i', {
      staticClass: "icon icon-down ml-6"
    })])], 1) : _vm._e(), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "w-md-200px my-n6",
      attrs: {
        "small": "",
        "block": "",
        "outlined": "",
        "color": "grey-d",
        "download": "",
        "href": item === null || item === void 0 ? void 0 : (_item$catalogue = item.catalogue) === null || _item$catalogue === void 0 ? void 0 : _item$catalogue.url
      },
      on: {
        "!click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_vm._v("Catalog Download"), _c('i', {
      staticClass: "icon icon-down ml-6"
    })])], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel-content', [_c('div', [_c('div', {
      staticClass: "mb-n30"
    }, [_c('v-divider'), _c('div', {
      staticClass: "py-20 py-md-30"
    }, [_vm.detect == 'mobile' ? _c('v-img', {
      attrs: {
        "width": "100%",
        "src": item === null || item === void 0 ? void 0 : (_item$image__mobile = item.image__mobile) === null || _item$image__mobile === void 0 ? void 0 : _item$image__mobile.url
      }
    }) : _vm._e(), _vm.detect == 'pc' ? _c('v-img', {
      attrs: {
        "width": "100%",
        "src": item === null || item === void 0 ? void 0 : (_item$image__desktop = item.image__desktop) === null || _item$image__desktop === void 0 ? void 0 : _item$image__desktop.url
      }
    }) : _vm._e()], 1), _c('v-divider'), _c('div', {
      staticClass: "py-20 py-md-30"
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item === null || item === void 0 ? void 0 : item.content)
      }
    })]), item !== null && item !== void 0 && (_item$documents = item.documents) !== null && _item$documents !== void 0 && _item$documents.length ? [_c('v-divider'), _c('v-expansion-panels', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-expansion-panel', {
      staticClass: "rounded-0 elevation-0"
    }, [_c('v-expansion-panel-header', {
      staticClass: "py-20 py-md-30 px-0 tit tit--xs"
    }, [_vm._v(" Citations ")]), _c('v-expansion-panel-content', [_c('v-row', _vm._l(item === null || item === void 0 ? void 0 : item.documents, function (document) {
      return _c('v-col', {
        key: document._id,
        attrs: {
          "cols": "12",
          "sm": "6"
        }
      }, [_c('v-card', {
        attrs: {
          "outlined": "",
          "tile": ""
        }
      }, [_c('v-card-title', [_vm._v(_vm._s(document.name))]), _c('v-card-text', [_vm._v(_vm._s(document.desc))]), _c('v-card-actions', [_c('v-btn', {
        attrs: {
          "color": "primary",
          "href": document.url,
          "target": "_blank"
        }
      }, [_vm._v(" Read Article ")])], 1)], 1)], 1);
    }), 1)], 1)], 1)], 1)] : _vm._e()], 2)])]), _c('v-divider')], 1)];
  })], 2), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-120px",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("List")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }