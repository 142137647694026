var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "name": "emailUser"
    },
    model: {
      value: _vm.emailUser,
      callback: function ($$v) {
        _vm.emailUser = $$v;
      },
      expression: "emailUser"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("@")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "name": "emailHost"
    },
    model: {
      value: _vm.emailHost,
      callback: function ($$v) {
        _vm.emailHost = $$v;
      },
      expression: "emailHost"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "name": "emailSelect"
    },
    on: {
      "change": function ($event) {
        _vm.emailHost = _vm.emailSelect;
      }
    },
    model: {
      value: _vm.emailSelect,
      callback: function ($$v) {
        _vm.emailSelect = $$v;
      },
      expression: "emailSelect"
    }
  }, 'v-select', Object.assign({}, _vm.$attrs, {
    items: _vm.items
  }), false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }