var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', _vm._b({
          attrs: {
            "title": "Product"
          },
          scopedSlots: _vm._u([{
            key: "searchField",
            fn: function () {
              return [_c('product-list-search')];
            },
            proxy: true
          }])
        }, 'sub-visual', {
          tabActive: _vm.tabActive
        }, false))];
      },
      proxy: true
    }])
  }, [_c('category-view-categories', _vm._b({}, 'category-view-categories', {
    _category: _vm._category
  }, false)), _c('v-container', [_vm.products__nrml.length + _vm.products__kits.length ? [_c('div', [_c('v-row', {
    staticClass: "ma-n14 mx-md-n30 my-md-n24"
  }, [_vm._l(_vm.items__nrml, function (item) {
    return _c('product-list-item', _vm._b({
      key: item === null || item === void 0 ? void 0 : item._id
    }, 'product-list-item', {
      item
    }, false));
  }), _vm._l(_vm.items__kits, function (item) {
    return _c('product-list-item', _vm._b({
      key: item === null || item === void 0 ? void 0 : item._id
    }, 'product-list-item', {
      item
    }, false));
  })], 2)], 1)] : [_c('v-container', [_c('v-card-title', {
    staticClass: "justify-center font-size-24 font-weight-light grey-90--text"
  }, [_vm._v(" No product has been uploaded, yet. ")])], 1)], _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "width": "5",
      "size": "100",
      "color": "primary",
      "indeterminate": ""
    }
  })], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }