<template>
    <div>
        <div class="mb-6" v-if="hasHead">
            <p class="font-size-14 text-right grey--text text--lighten-1"><span style="color: rgb(255, 0, 0);">*</span> Required entry</p>
        </div>
        <div class="table-style table-style--default">
            <slot />
        </div>

        <!-- 하단버튼 -->
        <div class="v-btn-group--bottom" v-if="hasFoot">
            <v-row justify="center" class="row--small">
                <v-col cols="6" sm="auto">
                    <v-btn outlined block color="grey-d" class="w-md-200px" @click="$emit('cancel')">Cancel</v-btn>
                </v-col>
                <v-col cols="6" sm="auto">
                    <v-btn block color="secondary" class="w-md-200px" @click="$emit('submit')">Submit</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hasHead: { type: Boolean, default: false },
        hasFoot: { type: Boolean, default: false },
    },
};
</script>

<style></style>
