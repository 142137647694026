<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="Product" v-bind="{ tabActive }">
                <!-- <template slot="subVisualImmersive">
                    <slide-banner v-if="detect == 'pc'" code="product-top-banners-desktop" />
                    <slide-banner v-if="detect == 'mobile'" code="product-top-banners-mobile" />
                </template> -->
            </sub-visual>
        </template>

        <v-container>
            <v-expansion-panels v-model="selected" flat multiple>
                <template v-for="item in items">
                    <v-expansion-panel class="rounded-0 elevation-0 mb-40 mb-md-60" :key="item._id">
                        <v-divider class="border-2 secondary" />
                        <v-expansion-panel-header class="product-item-header py-20 py-md-30 px-0 tit tit--xs">
                            <v-row class="ml-0">
                                <v-col> {{ item.name }} </v-col>
                                <v-col cols="auto">
                                    <v-row>
                                        <v-col cols="auto" v-if="item?.image__download?.url">
                                            <v-btn small block outlined color="grey-d" download :href="item?.image__download?.url" class="w-md-200px my-n6" @click.stop.capture>Image Download<i class="icon icon-down ml-6"></i></v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn small block outlined color="grey-d" download :href="item?.catalogue?.url" class="w-md-200px my-n6" @click.stop.capture>Catalog Download<i class="icon icon-down ml-6"></i></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>
                                <div class="mb-n30">
                                    <v-divider />
                                    <div class="py-20 py-md-30">
                                        <v-img width="100%" v-if="detect == 'mobile'" :src="item?.image__mobile?.url"></v-img>
                                        <v-img width="100%" v-if="detect == 'pc'" :src="item?.image__desktop?.url"></v-img>
                                    </div>
                                    <v-divider />
                                    <div class="py-20 py-md-30">
                                        <div v-html="item?.content" />
                                    </div>
                                    <template v-if="item?.documents?.length">
                                        <v-divider />
                                        <v-expansion-panels flat>
                                            <v-expansion-panel class="rounded-0 elevation-0">
                                                <v-expansion-panel-header class="py-20 py-md-30 px-0 tit tit--xs"> Citations </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                        <v-col cols="12" sm="6" v-for="document in item?.documents" :key="document._id">
                                                            <v-card outlined tile>
                                                                <v-card-title>{{ document.name }}</v-card-title>
                                                                <v-card-text>{{ document.desc }}</v-card-text>
                                                                <v-card-actions>
                                                                    <v-btn color="primary" :href="document.url" target="_blank"> Read Article </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </template>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                        <v-divider />
                    </v-expansion-panel>
                </template>
            </v-expansion-panels>
            <div class="mt-20 mt-md-30">
                <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn small outlined color="grey-d" class="w-120px" @click="$router.go(-1)">List</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SlideBanner from "@/components/client/banner/slide-banner.vue";
import MemberRouterLink from "@/components/client/academy/member-router-link.vue";
import ProductViewSlides from "@/components/client/products/product-view-slides.vue";
import DataListItem from "@/components/dumb/data-list-item.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SlideBanner,
        MemberRouterLink,
        ProductViewSlides,
        DataListItem,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        detect,
        product: undefined,
        selected: [],
    }),
    computed: {
        tabActive() {
            return this.product?.category?.name;
        },
        countries() {
            return this?.product?.countries?.map?.(({ name }) => name)?.join?.(", ");
        },
        items() {
            if (this.product) return [this.product].concat(this.product.products__related);
            else return [];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _product() {
            this.init();
        },
        items() {
            this.selected = [...Array(this.items.length).keys()];
        },
    },
    methods: {
        async init() {
            const { product } = await api.v1.shop.products.get({ _id: this._product });
            this.product = product;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    td.text-align-left {
        text-align: left !important;
    }
    .v-expansion-panel-content__wrap {
        padding: 0 0 30px;
    }

    .product-item-header {
        flex-direction: row-reverse;
    }
}
</style>
