<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Product" v-bind="{ tabActive }">
                <template #searchField>
                    <product-list-search />
                </template>
            </sub-visual>
        </template>

        <category-view-categories v-bind="{ _category }" />

        <v-container>
            <template v-if="products__nrml.length + products__kits.length">
                <div>
                    <v-row class="ma-n14 mx-md-n30 my-md-n24">
                        <product-list-item v-for="item in items__nrml" :key="item?._id" v-bind="{ item }" />
                        <product-list-item v-for="item in items__kits" :key="item?._id" v-bind="{ item }" />
                    </v-row>
                </div>
            </template>
            <template v-else>
                <v-container>
                    <v-card-title class="justify-center font-size-24 font-weight-light grey-90--text"> No product has been uploaded, yet. </v-card-title>
                </v-container>
            </template>
            <v-fade-transition>
                <v-overlay v-show="loading" color="white">
                    <v-progress-circular width="5" size="100" color="primary" indeterminate />
                </v-overlay>
            </v-fade-transition>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import { PRODUCT_TYPES } from "@/assets/variables";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";

import ProductListItem from "@/components/client/products/product-list-item.vue";
import ProductListSearch from "@/components/client/products/product-list-search.vue";
import CategoryViewCategories from "@/components/client/categories/category-view-categories.vue";

export default {
    components: {
        SubVisual,
        ClientPage,

        ProductListItem,
        ProductListSearch,
        CategoryViewCategories,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        products__nrml: [],
        products__kits: [],
        categories: [],

        loading: false,
        showsMore__nrml: false,
        showsMore__kits: false,
    }),
    computed: {
        tabActive() {
            return this.categories.find(({ code }) => code == this.code)?.name;
        },
        _category() {
            let { _category } = this.$route.query;
            if (_category) return _category;
            else return this.categories.find(({ code }) => code == this.code)?._id;
        },
        items__nrml() {
            return this.products__nrml;
        },
        items__kits() {
            return this.products__kits;
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.init();
    },
    watch: {
        _category() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.showsMore = false;
            this.products__nrml = [];
            this.products__kits = [];
            if (!this.categories.length) this.categories = (await api.v1.shop.categories.gets())?.categories;

            this.reroute().then(this.search);
        },
        async reroute() {
            // if (!this._category) {
            //     const { code } = (this.categories || []).filter(({ depth }) => depth == 1).sort((a, b) => a.sequence - b.sequence)[0] || {};
            //     this.$router.replace(`/categories/${code}`);
            // }
            const { code } = (this.categories || []).filter(({ depth }) => depth == 1).sort((a, b) => a.sequence - b.sequence)[0] || {};
            this.$router.replace(`/categories/${code}`);
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { _category } = this;
                this.products__nrml = (await api.v1.shop.products.gets({ headers: { sort: "onCategoryIndex__1" }, params: { _category, type: PRODUCT_TYPES.NORMAL.value } }))?.products;
                this.products__kits = (await api.v1.shop.products.gets({ headers: { sort: "onCategoryIndex__1" }, params: { _category, type: PRODUCT_TYPES.KIT.value } }))?.products;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>