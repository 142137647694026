<template>
    <v-tabs hide-slider grow show-arrows height="52">
        <slot />
    </v-tabs>
</template>

<script>
export default {
    props: {
    },
};
</script>

<style scoped>
.v-tabs >>>.v-item-group{
    position: relative;
}
.v-tabs >>>.v-slide-group__prev,
.v-tabs >>>.v-slide-group__next{
    position: absolute;
    top:50%;
    -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
    margin:0;
}
.v-tabs >>>.v-slide-group__prev:not(.v-slide-group__prev--disabled){
    left: -12px;
}
.v-tabs >>>.v-slide-group__next:not(.v-slide-group__next--disabled){
    right: -12px;
}
>>>.v-tab{
    padding: 0 !important;
}
>>>.v-tab:not(:first-child){
    margin-left: -1px !important;
}
>>>.v-tab .v-btn--active{
    background-color: var(--v-secondary-base) !important;
}
>>>.v-tab .v-btn--active .v-btn__content{
    color: #fff !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

/* v-tabs-block--grey */
.v-tabs-block--grey >>>.v-tab .v-btn--active{
    background-color: var(--default-dark-text-color) !important;
}
</style>
