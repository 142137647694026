<template>
    <v-btn v-bind="$attrs" @click.prevent="go" small block color="secondary" class="w-md-200px">
        <slot />
        <login-to-continue ref="LoginToContinue" />
    </v-btn>
</template>

<script>
import LoginToContinue from "./login-to-continue.vue";
export default {
    components: {
        LoginToContinue,
    },
    props: {
        to: { type: String, default: "/" },
    },
    computed: {
        hasUserLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    methods: {
        async go() {
            if (!this.hasUserLoggedIn) {
                this.$refs["LoginToContinue"].shows = true;
                return;
            } else {
                this.$router.push(this.to);
            }
        },
    },
};
</script>

<style></style>
