<template>
    <div v-if="banner" class="slide-banner">
        <swiper :options="swiperOptions" ref="slideBanner">
            <swiper-slide v-for="(slide, index) in banner.slides.filter((slide) => slide.image)" :key="index">
                <v-card tile flat class="slide-banner__img" :href="slide.url" target="_blank" :style="'background-image:url(' + slide.image + ');'"></v-card>
            </swiper-slide>
            <div class="swiper-control" slot="pagination">
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="ml-28 mr-12">
                    <div class="swiper-play d-none"><i class="icon icon-play-dark"></i></div>
                    <div class="swiper-pause"><i class="icon icon-pause-dark"></i></div>
                </div>
                <div class="swiper-button-prev" slot="button-prev"><i class="icon icon-chevron-left-sm-dark"></i></div>
                <div class="swiper-button-next" slot="button-next"><i class="icon icon-chevron-right-sm-dark"></i></div>
            </div>
        </swiper>
    </div>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => {
        return {
            banner: null,

            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: ".slide-banner .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".slide-banner .swiper-button-next",
                    prevEl: ".slide-banner .swiper-button-prev",
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { banner } = await api.v1.banners.get({ code: this.code });
                this.banner = banner;
                await this.render();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async render() {
            await this.$nextTick(() => {
                const swiperPauseBtn = document.querySelector(".swiper-pause");
                const swiperPlayBtn = document.querySelector(".swiper-play");

                swiperPauseBtn.addEventListener("click", () => {
                    setTimeout(() => {
                        this.$refs.slideBanner.$swiper.autoplay.stop();
                        swiperPlayBtn.classList.remove("d-none", "d-inline-flex");
                        swiperPauseBtn.classList.remove("d-none", "d-inline-flex");
                        swiperPauseBtn.classList.add("d-none");
                        swiperPlayBtn.classList.add("d-inline-flex");
                    }, 100);
                });
                swiperPlayBtn.addEventListener("click", () => {
                    setTimeout(() => {
                        this.$refs.slideBanner.$swiper.autoplay.start();
                        swiperPlayBtn.classList.remove("d-none", "d-inline-flex");
                        swiperPauseBtn.classList.remove("d-none", "d-inline-flex");
                        swiperPlayBtn.classList.add("d-none");
                        swiperPauseBtn.classList.add("d-inline-flex");
                    }, 100);
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-banner {
    ::v-deep {
        .swiper-container {
            height: 100%;
        }
        .swiper-slide {
            background-color: #fff;
        }
        .swiper-control {
            top: auto;
            bottom: 4px;
            max-width: var(--container-md);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
        .swiper-pagination {
            position: static;
            bottom: auto;
            left: auto;
            transform: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: auto;
            font-size: 1.6rem;
            color: var(--v-secondary-base);
            margin: 0 -13px;
        }
        .swiper-pagination-bullet {
            position: relative;
            margin: 2px 13px;
            opacity: 1;
            background-color: rgba(34, 34, 34, 0.2);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                display: block;
                width: 26px;
                height: 26px;
                opacity: 0;
                border: 2px solid var(--v-secondary-base);
                border-radius: 100%;
            }
            &-active {
                background-color: var(--v-secondary-base);
                &::before {
                    opacity: 1;
                }
            }
        }
        .swiper-play,
        .swiper-pause {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            top: auto;
            left: auto;
            right: auto;
            transform: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background: none;
            border: 0;
            margin-top: 0;
            &:after {
                display: none;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .slide-banner {
        ::v-deep {
            .swiper-control {
                bottom: 16px;
            }
            .swiper-pagination {
                margin-left: 13px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .slide-banner {
        ::v-deep {
            .swiper-control {
                bottom: 8%;
            }
        }
    }
}

/* slide-banner */
.slide-banner {
    width: 100%;
    color: #222;
    .container {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: var(--container-md);
        height: 100%;
    }
    &__inner {
        position: relative;
    }
    &__img {
        display: block;
        width: 100%;
        padding-top: calc(2 / 3 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    &__tit {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    &__txt {
        font-size: 1.6rem;
        font-weight: 300;
        & + .slide-banner__info {
            margin-top: 14px;
        }
    }
    &__info {
        > ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: -4px -8px;
            > li {
                position: relative;
                font-size: 1.4rem;
                font-weight: 300;
                line-height: 1;
                padding: 4px 8px;
            }
        }
        & + .slide-banner__btn {
            margin-top: 20px;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .slide-banner {
        &__img {
            width: 100%;
            padding-top: calc(500 / 1920 * 100%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        &__tit {
            font-size: 5.2rem;
            margin-bottom: 20px;
        }
        &__txt {
            font-size: 1.8rem;
        }
        &__info {
            > ul > li {
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    -webkit-transform: translate(0, calc(-50% + 1px));
                    -ms-transform: translate(0, calc(-50% + 1px));
                    transform: translate(0, calc(-50% + 1px));
                    display: block;
                    width: 1px;
                    height: 10px;
                    background-color: #222;
                }
                &:first-child::before {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .slide-banner {
        &__tit {
            font-size: 7.8rem;
            margin-bottom: 12px;
            & + .slide-banner__info {
                margin-top: 26px;
            }
        }
        &__txt {
            font-size: 2.2rem;
        }
        &__info {
            > ul {
                margin: -6px -12px;
                > li {
                    font-size: 2rem;
                    padding: 6px 12px;
                }
            }
            & + .slide-banner__btn {
                margin-top: 56px;
            }
        }
    }
}
@media (min-width: 1921px) {
    .slide-banner {
        &__img {
            width: 1920px;
            height: 500px;
            margin: auto;
            padding-top: 0;
            background-size: contain;
        }
    }
}
</style>
