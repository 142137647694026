var render = function render(){
  var _vm$selectedItem, _vm$selectedItem2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "transition": "fade-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-tabs-items', {
          attrs: {
            "value": _vm.selectedIndex
          }
        }, _vm._l(_vm.slides, function (slide) {
          return _c('v-tab-item', _vm._g(_vm._b({
            key: slide._id,
            staticClass: "cursor-pointer",
            attrs: {
              "eager": ""
            }
          }, 'v-tab-item', attrs, false), on), [slide.type == 'image' ? [_c('v-img', {
            attrs: {
              "aspect-ratio": 1 / 1,
              "contain": "",
              "src": slide.url
            }
          })] : _vm._e(), slide.type == 'video' ? [_c('v-img', {
            attrs: {
              "aspect-ratio": 1 / 1
            }
          }, [_c('v-overlay', {
            staticStyle: {
              "pointer-events": "none"
            },
            attrs: {
              "absolute": "",
              "z-index": "0"
            }
          }, [_c('video', {
            attrs: {
              "src": slide.url,
              "height": "500px"
            }
          })]), _c('v-overlay', {
            attrs: {
              "absolute": "",
              "z-index": "0",
              "color": "transparent"
            }
          }, [_c('v-icon', {
            attrs: {
              "x-large": ""
            }
          }, [_vm._v("mdi-play")])], 1)], 1)] : _vm._e()], 2);
        }), 1)];
      }
    }]),
    model: {
      value: _vm.showsDialog,
      callback: function ($$v) {
        _vm.showsDialog = $$v;
      },
      expression: "showsDialog"
    }
  }, [_c('v-overlay', [_c('v-container', [_c('div', {
    staticClass: "v-dialog--fullscreen__close"
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsDialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "line-height-0"
  }, [((_vm$selectedItem = _vm.selectedItem) === null || _vm$selectedItem === void 0 ? void 0 : _vm$selectedItem.type) == 'image' ? [_c('v-img', {
    attrs: {
      "src": _vm.selectedItem.url
    }
  })] : _vm._e(), ((_vm$selectedItem2 = _vm.selectedItem) === null || _vm$selectedItem2 === void 0 ? void 0 : _vm$selectedItem2.type) == 'video' ? [_c('video', {
    attrs: {
      "src": _vm.selectedItem.url,
      "width": "100%",
      "controls": ""
    }
  })] : _vm._e()], 2)])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-14 pt-md-20"
  }, [_c('v-tabs-items', {
    attrs: {
      "value": _vm.page
    }
  }, _vm._l(_vm.pagedItems, function (slides, index) {
    return _c('v-tab-item', {
      key: index,
      staticClass: "overflow-hidden",
      attrs: {
        "value": index + 1,
        "eager": ""
      }
    }, [_c('v-row', {
      staticClass: "row--x-small"
    }, _vm._l(slides, function (slide, index) {
      return _c('v-col', {
        key: slide._id,
        staticClass: "cursor-pointer",
        attrs: {
          "cols": "3"
        },
        on: {
          "mouseenter": function ($event) {
            return _vm.setSelectedIndex(slide);
          }
        }
      }, [_c('v-sheet', {
        staticClass: "product-view-slides__thumb-slide-img",
        class: {
          'secondary': _vm.selectedIndex == 4 * _vm.page - 4 + index
        },
        attrs: {
          "outlined": ""
        }
      }, [_c('div', {
        staticClass: "white"
      }, [(slide === null || slide === void 0 ? void 0 : slide.type) == 'image' ? [_c('v-img', {
        attrs: {
          "aspect-ratio": 1 / 1,
          "contain": "",
          "src": slide.url,
          "width": "100%"
        }
      })] : _vm._e(), (slide === null || slide === void 0 ? void 0 : slide.type) == 'video' ? [_c('v-img', {
        attrs: {
          "aspect-ratio": 1 / 1
        }
      }, [_c('v-overlay', {
        staticStyle: {
          "pointer-events": "none"
        },
        attrs: {
          "absolute": "",
          "z-index": "0"
        }
      }, [_c('video', {
        attrs: {
          "src": slide.url,
          "height": "100px"
        }
      })]), _c('v-overlay', {
        attrs: {
          "absolute": "",
          "z-index": "0",
          "color": "transparent"
        }
      }, [_c('v-icon', {
        attrs: {
          "x-large": ""
        }
      }, [_vm._v("mdi-play")])], 1)], 1)] : _vm._e()], 2)])], 1);
    }), 1)], 1);
  }), 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_c('div', {
    staticClass: "pagination-default"
  }, _vm._l(_vm.dotLength, function (index) {
    return _c('span', {
      key: `dot-${index}`,
      staticClass: "pagination-default__bullet",
      class: {
        'pagination-default__bullet--active': index == _vm.page
      },
      on: {
        "click": function ($event) {
          (_vm.page = index) && (_vm.selectedIndex = (index - 1) * _vm.itemsPerPage);
        }
      }
    });
  }), 0)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }