<template>
    <div>
        <!-- S: 썸네일 -->
        <v-card outlined>
            <v-dialog v-model="showsDialog" fullscreen transition="fade-transition">
                <template #activator="{ attrs, on }">
                    <v-tabs-items :value="selectedIndex">
                        <v-tab-item eager v-for="slide in slides" :key="slide._id" v-bind="attrs" v-on="on" class="cursor-pointer">
                            <template v-if="slide.type == 'image'">
                                <v-img :aspect-ratio="1 / 1" contain :src="slide.url" />
                            </template>
                            <template v-if="slide.type == 'video'">
                                <v-img :aspect-ratio="1 / 1">
                                    <v-overlay absolute z-index="0" style="pointer-events:none">
                                        <video :src="slide.url" height="500px" />
                                    </v-overlay>
                                    <v-overlay absolute z-index="0" color="transparent">
                                        <v-icon x-large>mdi-play</v-icon>
                                    </v-overlay>
                                </v-img>
                            </template>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
                <v-overlay>
                    <v-container>
                        <div class="v-dialog--fullscreen__close">
                            <v-btn fab tile @click="showsDialog = false">
                                <v-icon x-large>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <div class="line-height-0">
                            <template v-if="selectedItem?.type == 'image'">
                                <v-img :src="selectedItem.url" />
                            </template>
                            <template v-if="selectedItem?.type == 'video'">
                                <video :src="selectedItem.url" width="100%" controls />
                            </template>
                        </div>
                    </v-container>
                </v-overlay>
            </v-dialog>
        </v-card>
        <!-- E: 썸네일 -->

        <!-- S: 썸네일 슬라이드 -->
        <div class="pt-14 pt-md-20">
            <v-tabs-items :value="page">
                <v-tab-item v-for="(slides, index) in pagedItems" :key="index" :value="index + 1" eager class="overflow-hidden">
                    <v-row class="row--x-small">
                        <v-col cols="3" v-for="(slide, index) in slides" :key="slide._id" @mouseenter="setSelectedIndex(slide)" class="cursor-pointer">
                            <v-sheet outlined class="product-view-slides__thumb-slide-img" :class="{ 'secondary' : selectedIndex == ((4 * page) - 4) + index }">
                                <div class="white">
                                    <template v-if="slide?.type == 'image'">
                                        <v-img :aspect-ratio="1 / 1" contain :src="slide.url" width="100%" />
                                    </template>
                                    <template v-if="slide?.type == 'video'">
                                        <v-img :aspect-ratio="1 / 1">
                                            <v-overlay absolute z-index="0" style="pointer-events:none">
                                                <video :src="slide.url" height="100px" />
                                            </v-overlay>
                                            <v-overlay absolute z-index="0" color="transparent">
                                                <v-icon x-large>mdi-play</v-icon>
                                            </v-overlay>
                                        </v-img>
                                    </template>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <!-- E: 썸네일 슬라이드 -->

        <div class="mt-30 mt-md-40">
            <div class="pagination-default">
                <span v-for="index in dotLength" :key="`dot-${index}`" class="pagination-default__bullet" :class="{'pagination-default__bullet--active' : index == page}" @click="(page = index) && (selectedIndex = (index - 1) * itemsPerPage)"></span>
            </div>
        </div>

        <!-- <v-row justify="center" no-gutters>
            <v-col cols="auto" v-for="index in dotLength" :key="`dot-${index}`" class="mx-4">
                <v-fade-transition hide-on-leave leave-absolute>
                    <v-sheet v-show="index != page" style="position:relative;" @click="(page = index) && (selectedIndex = (index - 1) * itemsPerPage)">
                        <v-icon>mdi-circle-medium</v-icon>
                    </v-sheet>
                </v-fade-transition>
                <v-fade-transition hide-on-leave leave-absolute>
                    <v-sheet v-show="index == page" style="position:relative;">
                        <v-icon color="lime darken-2">mdi-circle-medium</v-icon>
                        <v-overlay absolute z-index="0" color="transparent">
                            <v-icon color="lime darken-2">mdi-circle-outline</v-icon>
                        </v-overlay>
                    </v-sheet>
                </v-fade-transition>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
export default {
    props: {
        slides: { type: Array, default: () => [] },
    },
    data: () => ({
        page: 1,
        itemsPerPage: 4,
        selectedIndex: 0,

        showsDialog: false,
    }),
    computed: {
        dotLength() {
            return Math.ceil(this.slides.length / this.itemsPerPage);
        },
        pagedItems() {
            return this.slides.reduce((items, item) => {
                let list = items[items.length - 1];
                if (!list || list.length >= this.itemsPerPage) {
                    list = [];
                    items.push(list);
                }
                list.push(item);
                return items;
            }, []);
        },
        selectedItem() {
            return this.slides[this.selectedIndex];
        },
    },
    methods: {
        setSelectedIndex({ _id }) {
            this.selectedIndex = this.slides.findIndex((slide) => slide._id == _id);
        },
    },
};
</script>

<style scoped>
.product-view-slides__thumb-slide-img:hover{
    border-color: var(--v-secondary-base) !important;
}
</style>
