<template>
    <!-- <sub-depth-tab-wrap v-if="categories__depth2.length"> -->
    <sub-depth-tab-wrap>
        <div class="mb-20 mb-md-30">
            <v-row align="center" justify="center" class="row--x-small">
                <v-col cols="auto">
                    <v-chip rounded color="transparent" :class="{ 'secondary white--text': !categories__depth2.some(({ _id }) => _id == _category || _id == category?._parent) }" @click="emit(null)">All</v-chip>
                </v-col>
                <template v-for="item in categories__depth2">
                    <v-col cols="auto" :key="item._id">
                        <v-chip rounded color="transparent" :class="{ 'secondary white--text': item._id == _category || item._id == category?._parent }" @click="emit(item._id)">{{ item?.name }}</v-chip>
                    </v-col>
                </template>
            </v-row>
        </div>

        <v-container v-if="categories__depth3.length">
            <v-tabs-block>
                <v-tab>
                    <v-btn outlined block color="grey-e6" :class="{ 'v-btn--active' : categories__depth2.some(({ _id }) => _id == _category) }" @click="emit(categories__depth3[0]?._parent)"> All </v-btn>
                </v-tab>
                <template v-for="item in categories__depth3">
                    <v-tab :key="item._id">
                        <v-btn outlined block color="grey-e6" :class="{ 'v-btn--active' : _category == item._id }" @click="emit(item._id)">
                            {{ item?.name }}
                        </v-btn>
                    </v-tab>
                </template>
            </v-tabs-block>
        </v-container>
    </sub-depth-tab-wrap>
</template>

<script>
import api from "@/api";

import VTabsBlock from "@/components/client/dumb/v-tabs-block.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";

export default {
    components: {
        VTabsBlock,
        SubDepthTabWrap,
    },
    props: {
        _category: { type: String, default: null },
    },
    data: () => ({
        category: null,

        categories: [],

        loading: false,
    }),
    computed: {
        categories__depth2() {
            return this.items(2);
        },
        categories__depth3() {
            return this.items(3);
        },
    },
    mounted() {
        this.init();
        this.sync();
    },
    watch: {
        _category() {
            this.sync();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                this.categories = (await api.v1.shop.categories.gets({ params: { depth: 1 } }))?.categories;
                this.sync();
            } finally {
                this.loading = false;
            }
        },

        sync() {
            this.category = this.findCategory(this._category);
        },

        emit(_category) {
            const { ...query } = this.$route.query;

            query._category = _category;
            if (!query._category) delete query._category;

            this.$router.push({ query });
        },

        findCategory(_category, categories = null) {
            if (!categories) categories = this.categories;

            for (var category of categories) {
                if (category._id == _category) return category;
                else if (category.children) {
                    var result = this.findCategory(_category, category.children);
                    if (result) return result;
                }
            }

            return null;
        },

        items(depth, categories = this.categories) {
            if (depth == 1) return categories;
            if (this.category) {
                for (var category of categories) {
                    if (category._id == this.category._id) {
                        if (depth <= this.category.depth) return categories;
                        else if (this.category.depth + 1 == depth) return category.children;
                    } else if (category.children) {
                        var result = this.items(depth, category.children);
                        if (result.length) return depth <= category.depth ? categories : result;
                    }
                }
            }

            return [];
        },

        select(depth) {
            if (this.category) return [...this.category._parents, this.category._id][depth - 1] || null;
            return null;
        },

        findDepth(categories = null, depth = 1) {
            var maxDepth = depth;
            if (!categories) categories = this.categories;
            for (var category of categories) {
                if (category.children && category.children.length) {
                    var result = this.findDepth(category.children, depth + 1);
                    if (maxDepth < result) maxDepth = result;
                }
            }
            return maxDepth;
        },

        itemDisabled(item) {
            return this._disableds.includes(item?._id || item);
        },
    },
};
</script>


<style lang="scss" scoped>
::v-deep .v-chip {
    color: var(--default-light-text-color);
}
</style>
